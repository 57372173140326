<template lang="pug">
.portfolio
	Menu
		template(#title)
			.flex.items-center
				| Portfolio Availability
				Tag.mt-4.ml-8(:label="target" type="green")

	.container
		template(v-if="isMounted")
			.flex.mb-24.mt-24
				.header-left
					select(v-model="group" :disabled="selectedType === 'new'")
						option(v-for="(item, i) in groupOptions" :key="i" :value="item") Group By: {{ item }}
					select.provider(v-model="selectedProvider")
						option(value="default" selected disabled hidden) Select a provider
						option(
							v-for="(item, i) in providers"
							:key="i"
							:value="item"
						) Provider is: {{ item }}
					select.provider(v-model="selectedType")
						option(v-for="(type, i) in selectedTypes" :key="i" :value="type") Type is: {{ type }}
				.header-right
					Tabs(:tabs="tabsData" type-tab="table-tabs" v-model="tab")

			.flex.justify-between.charts
				ContainerForData(:isLoading="isLoading")
					template(#header-left)
						.flex.items-center
							p Summary
							Tooltip(
								v-if="false"
								trigger="hover"
							)
								template(slot="reference")
									HelpCircle.icon
								span 'Tooltip'
					template(#data)
						ShellDoughuntChart(v-bind="chartPropsDoughunt")

				ContainerForData(:isLoading="isLoading")
					template(#header-left)
						.flex.items-center
							p Portfolio Availability Over Time
							Tooltip(
								v-if="false"
								trigger="hover"
							)
								template(slot="reference")
									HelpCircle.icon
								span 'Tooltip'
					template(#data)
						LineChart(v-bind="chartPropsLine")

			.flex.justify-between.charts
				ContainerForData(width="100%" :isLoading="isLoading")
					template(#header-left)
						.flex.items-center
							p Portfolio availability by {{group}}
					template(#data)
						BarChart(
							:chartData="availabilityChartData"
							:options="availabilityChartOptions"
							ref="bar"
							:style="{'height': `${100 + (40 * (lengthOfResult)) }px`}"
						)

			ContainerForData(width="100%" :isLoading="isLoading")
				template(#header-left)
					.flex.items-center
						select(v-model="status")
							option(v-for="item in statusOptions" :value="item") Status: {{ item }}
				template(#header-right)
					.flex
						.table-button(v-if="isVisibleTablePagination")
							button.button(@click="earlerDates" :disabled="isFirstPage") &#x2039; Earler dates
							button.button(@click="laterDates" :disabled="isLastPage") Later dates &#x203A;
						Search(v-model="search" @change="fetch")
				template(#data)
					template(v-if="tableOptions.data.length")
						Table.brands__table(v-bind="tableOptions")
							template(#image="{item, i, value}")
								.table-image(:style="{backgroundImage: `url(${value})`}")
							template(#description="{item, i, value}")
								a(
									v-if="item.show_url"
									:href="item.sku_url"
									target="_blank"
								) {{ value.name }}
								.name(v-else) {{ value.name }}
								div
									strong RPC
									span.ml-8 {{ value.rpc }}
								div
									strong UPC
									span.ml-8 {{ value.upc }}
							template(#date="{ value }")
								.circle(:class="!value ? 'circle-red' : value == 2 ? 'circle-gray' : 'circle-green'")
					template(v-else)
						pre Data not found
				template(
					v-if="hasPagination"
					#pagination
				)
					.element-container__content.flex.items-center.justify-center
						pagination(
							:total="resp.count"
							:current="current"
							:limit="limit"
							@change-page="current = $event"
						)

		.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
const YESTERDAY = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)

import { renameYandexByName } from '@/helpers/RenameYandexPokupki'
import Menu from '@/components/Menu/Menu.vue'
import ShellDoughuntChart from "@/components/Nestle/ShellDoughuntChart"
import ContainerForData from "@/components/Nestle/ContainerForData"
import LineChart from "@/components/Chart/LineChart"
import Period from "@/components/Period/Period"
import Tooltip from '@/components/Elements/Tooltip.vue'
import HelpCircle from 'vue-material-design-icons/HelpCircle.vue'
import DatePicker from '@/components/Elements/DatePicker.vue'
import Tabs from "@/components/Nestle/Tabs"
import Table from "@/components/Table/Table"
import Search from "../../components/Nestle/Search"
import Pagination from "@/components/Pagination/Pagination.vue"
import BarChart from "@/components/Chart/BarChart"
import Tag from '@/components/Tag/Tag'

export default {
	components: {
		Search,
		Menu,
		Table,
		Tabs,
		Period,
		LineChart,
		ContainerForData,
		ShellDoughuntChart,
		Tooltip,
		HelpCircle,
		DatePicker,
		Pagination,
		BarChart,
		Tag,
	},
	// TODO доработать date
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},
	data: () => {
		return {
			target: '85%',
			resp: null,
			group: 'Store',
			selectedProvider: 'default',
			groupOptions: ['Store', 'Brand', 'Category'],
			selectedType: 'old',
			selectedTypes: ['old', 'new'],
			providers: [],
			status: 'any',
			statusOptions: ['any', 'available', 'unavailable', 'void'],
			tab: 'number',
			tabsData:[
				{
					value: 'number',
					title: 123
				},
				{
					value: 'percent',
					title: '%'
				}
			],
			list: [],
			search: null,
			current: 1,
			limit: 20,
			offset: 0,
			amountOfDates: 6,
			tableDates: null,
			from: null,
			to: null,

			groupMapping: {
				Store: {
					name: 'stores',
					nameOfIdKey: 'storeId'
				},
				Brand: {
					name: 'brands',
					nameOfIdKey: 'brandId'
				},
				Category: {
					name: 'categories',
					nameOfIdKey: 'categoryId'
				},
			},

			isLoading: true,
			isMounted: false,
		}
	},
	computed:{
		hasPagination() {
			if (!this.resp?.count) return
			return this.resp.count / this.limit > 1
		},
		lengthOfResult() {
			if (!this.resp[this.groupMapping[this.group].name]) return
			return Object.keys(this.resp[this.groupMapping[this.group].name]).length
		},
		availabilityChartOptions() {
			return {
				indexAxis: 'x',
				plugins: {
					datalabels: {
						display: function (context){
							return context.dataset.data[context.dataIndex]
						},
						color: '#303030',
						backgroundColor: 'transparent',
						borderColor: 'transparent',
						font: {
							weight: 'bold',
							size: 13
						},
					},
					responsive: true,
					tooltip: {
						enabled: false
					},
					interaction: {
						intersect: false,
					},
					legend: {
						display: true,
						position: 'bottom',
						labels: {
							font: {
								size: 14
							}
						}
					},
					title: {
						position: 'left',
						display: true,
						text: 'Product count',
						font: {
							size: 14
						}
					}
				},
				responsive: true,
				scales: {
					x: {
						stacked: true,
						ticks: {
							maxRotation: 70, // Максимальный угол поворота метки, если она не влезает
							autoSkip: false, // Автоматически пропускать метки, если они пересекаются
						},
					},
					y: {
						stacked: true,
						ticks: {
							maxTicksLimit: 10,
						},
					}
				}
			}
		},
		availabilityChartData() {
			const groupObject = this.resp[this.groupMapping[this.group].name];
			if (!groupObject) return;

			return {
				labels: Object.values(groupObject).map((item) => item[this.group.toLowerCase()].name),
				datasets: [
					{
						label: 'Available',
						borderColor: '#4CAF50',
						backgroundColor: '#4CAF50',
						data: Object.values(groupObject).map((item) => item?.summary.availability || 0),
					},
					{
						label: 'Unavailable',
						borderColor: '#F44336',
						backgroundColor: '#F44336',
						data: Object.values(groupObject).map((item) => -item?.summary.unavailable || 0),
					},
				],
			}
		},
		numberInRadialChart(){
			return this.tab === 'number'
		},
		optionsDoughunt() {
			return {
				cutout: 100,
				borderWidth: 1
			}
		},
		chartDataDoughunt() {
			let summary = this.resp?.summary	// {passed: 3379, total: 3762}
			// let data = Object.values(summary)
			// let labels = Object.keys(summary)
			const available = summary.passed
			const unavailable = Number(summary.total) - Number(summary.passed) - Number(summary.void)
			const amountOfVoid = summary.void
			const data = [available, unavailable, amountOfVoid]
			const labels = ['available', 'unavailable', 'void']
			return {
				labels,
				datasets: [{
					data,
					backgroundColor: [
						'#4CAF50',
						'#F44336',
						'#9E9E9E',
					],
				}]
			}
		},
		chartPropsDoughunt() {
			return {
				options: this.optionsDoughunt,
				chartData: this.chartDataDoughunt,
				percent: this.numberInRadialChart ? this.resp.summary.passed : `${Math.floor(this.resp.summary.passed * 100 / this.resp.summary.total)}%`,
				value: this.resp.summary.total,
			}
		},
		chartDataLine() {
			return {
				labels: this.resp.historical.list.map((item) => item.date),
				datasets: [
					{
						label: 'Нет в наличии. Не отображается на маркете',
						data: this.resp.historical.list.map((item) => item.void),
						borderColor: '#9E9E9E',
						backgroundColor: '#9E9E9E',
						fill: {
							target: 'start',
							above: 'rgba(158, 158, 158, 0.8)',
						},
					},
					{
						label: 'Нет в наличии. Отображается на маркете',
						data: this.resp.historical.list.map((item) => item.out),
						borderColor: '#F44336',
						backgroundColor: '#F44336',
						fill: {
							target: 'start',
							above: 'rgba(244, 67, 54, 0.8)',
						},
					},
					{
						label: 'В наличии',
						data: this.resp.historical.list.map((item) => item.stock),
						borderColor: '#4CAF50',
						backgroundColor: '#4CAF50',
						fill: {
							target: 'start',
							above: 'rgba(76, 175, 80, 0.8)',
						},
					}
				]
			}
		},
		optionsLine() {
			return {
				type: 'line',
				pointStyle: 'circle',
				pointRadius: 4,
				interaction: {
					mode: 'index'
				},
				plugins: {
					responsive: true,
					interaction: {
						intersect: false,
					},
					legend: {
						display: true,
						position: 'bottom',
						reverse: true,
						labels: {
							font: {
								size: 14
							}
						}
					},
					tooltip: {
						xAlign: 'average',
						borderColor: '#000000',
						itemSort: (a, b) => {
							return b.formattedValue-a.formattedValue
						}
					},
				},
				scales: {
					y: {
						stacked: true,
					},
				}

			}
		},
		chartPropsLine() {
			return {
				options: this.optionsLine,
				chartData: this.chartDataLine,
			}
		},
		tableDateCols() {
			return this.tableDates.slice(this.from, this.to)
		},
		isVisibleTablePagination() {
			if (!this.tableDates) return
			return this.tableDatesLength > this.amountOfDates
		},
		tableDatesLength() {
			if (!this.tableDates) return
			return this.tableDates.length
		},
		tableDataDefault() {
			return this.resp?.products
		},
		tableColumns() {
			return [
				{
					title: 'Online Store',
					width: 80,
					value: (item)=>{
						return item.store.name
					}
				},
				{
					title: 'Image',
					width: 80,
					slot: 'image',
					value: (item)=>{
						return item.image
					}
				},
				{
					title: 'Description',
					width: 240,
					slot: 'description',
					value: (item)=>{
						return {
							name: item.name,
							rpc: item.RPC,
							upc: item.UPC
						}
					}
				},
					...this.tableDateCols,
				{
					title: this.$utils.format(YESTERDAY, 'MMM dd'),
					width: 60,
					slot: 'date',
					value: (item) => {
						return item.availability[item.availability.length - 1].value
					}
				},
			]
		},
		tableOptions() {
			return {
				sort: {field: 'name', order: 'desc'},
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
		tableData() {
			return this.list.concat(this.tableDataDefault)
		},
		isFirstPage() {
			return this.from === 0
		},
		isLastPage() {
			if (!this.tableDates) return
			return this.to === this.tableDatesLength
		},
	},
	methods: {
		earlerDates(){
			if (this.from < this.amountOfDates) {
				this.from = 0
				this.to = this.amountOfDates
			} else {
				this.to = this.to - this.amountOfDates
				this.from = this.from - this.amountOfDates
			}
		},
		laterDates(){
			if (this.to > this.tableDatesLength - this.amountOfDates) {
				this.from = this.tableDatesLength - this.amountOfDates
				this.to = this.tableDatesLength
			} else {
				this.to = this.to + this.amountOfDates
				this.from = this.from + this.amountOfDates
			}
		},
		setTableDates() {
			const arrs = []
			const historical = this.resp?.historical
			const lengthOfDates = historical.list.length

			for (let i = 0; i < lengthOfDates; i++) {
				arrs.push({
					title: this.$utils.format(this.$utils.parseISO(historical.list[i].date), 'MMM dd'),
					width: 360 / this.amountOfDates,
					slot: 'date',
					value: (item) => item.availability[i].value
				})
			}

			const lastDate = historical.list[lengthOfDates - 1].date
			const yesterdayDate = this.$utils.format(YESTERDAY, 'yyy-MM-dd')

			if (lastDate === yesterdayDate) {
				arrs.length = arrs.length - 1
			}

			this.to = arrs.length
			this.from = arrs.length - this.amountOfDates

			return arrs
		},
		async fetch() {
			this.isMounted = true

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
				searchQuery: this.search || null,
				status: this.status,
				provider: this.selectedProvider !== 'default' ? this.selectedProvider : '',
				type: this.selectedType === 'new' ? 'new' : '',
			};
			try {
				this.isLoading = true
				const result = await this.$api.nestle.getAvailability(params)
				const renamedItems = renameYandexByName(result)
				this.resp = renamedItems
				this.tableDates = await this.setTableDates()

				this.providers = Object.values(this.resp.providers).filter((item) => item != 'null')
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			async handler() {
				console.log('NeedUpateResult')
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
		selectedProvider: {
			handler() {
				this.offset = 0
				this.current = 1
				this.fetch()
			},
		},
		selectedType: {
			handler() {
				this.offset = 0
				this.current = 1
				this.fetch()
			},
		},
		group() {
			this.offset = 0
			this.current = 1
			this.fetch()
		},
		status() {
			this.offset = 0
			this.current = 1
			this.fetch()
		},
	},
}
</script>

<style lang="scss" scoped>
.portfolio {
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;

		::v-deep.control__input{
			color: color(white);
			font-weight: 400;
		}
	}
}

h1{
	color: color(gray-700);
}
.header{
	margin-bottom: 32px;
	&-right{
		margin-left: auto;
	}
	&-button {
		background-color: #fff;
		margin-top: 1px;
		padding: 10px 5px;
		border-radius: 3px;
		color: color(gray-700);
		border: 1px color(gray-400) solid;
		&:not(:last-child){
			margin-right: 8px;
		}
		&:focus{
			outline: none;
		}
	}
}
.icon{
	color: color(gray-500);
	position: absolute;
	margin-left: 8px;
	margin-top: -6px;
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}
}
.icon-button{
	color: color(gray-700);
	::v-deep.material-design-icon__svg {
		width: 15px;
		height: 15px;
	}

	margin-right: 8px;
}
::v-deep.chart {
	width: 100%;
}
.data-range-picker{
	position: relative;
	background-color: #fff;
	padding: 0 25px 0 5px;
	border: 1px color(gray-400) solid;
	border-radius: 3px;
	&:after{
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		font-weight: 800;
		top: 7px;
		right: 6px;
		border: 5px solid transparent;
		border-top: 5px solid black;
	}
}
.mb-24 {
	margin-bottom: 24px;
}
.ml-8 {
	margin-left: 8px;
}
select{
	padding: 0 5px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	color: color(gray-700);

	&:focus{
		outline: none;
	}
}
.provider {
	margin-left: 20px;
}
::v-deep.control__input{
	padding: 10px 5px;
	border: none;
	width: 80px;
	font-weight: 800;
	color: color(gray-700);
	cursor: pointer;
	&:focus{
		outline: none;
	}
}
.search input{
	position: relative;
	padding-left: 30px;
	width: 300px;
	outline: 1px color(gray-400) solid;
	border-radius: 3px;
	z-index: 1;
}
.search .icon{
	position: absolute;
	z-index: 2;
	top: 15px;
}
.charts{
	margin-bottom: 38px;
}
.table-image{
	width: 40px;
	height: 40px;
	background-size: contain;
	filter: inset(1);
	background-repeat: no-repeat;
	background-position: center center;
}
.circle{
	width: 15px;
	height: 15px;
	border-radius: 50%;
	&-red{
		background-color: color(red);
	}
	&-green{
		background-color: color(green);
	}
	&-gray{
		background-color: color(gray-600);
	}
}
.table-button button{
	font-size: 14px;
	background-color: #fff;
	outline: 1px color(gray-400) solid;
	padding: 0 5px;
	border-radius: 3px;
	margin-top: 1px;
	color: #303030;
	&:disabled {
		cursor: not-allowed;
		color: color(gray-600);
	}
}
.name {
	cursor:default
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>
